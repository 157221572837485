<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <div>
      <h1>Income Report</h1>
      <hr>
      <b-field label="Start Date">
        <b-datepicker
          v-model="reportingPeriodStart"
          placeholder="Start"
          icon="calendar-today"
          trap-focus>
        </b-datepicker>
      </b-field>
      <b-field label="End Date">
        <b-datepicker
          v-model="reportingPeriodEnd"
          placeholder="End"
          icon="calendar-today"
          trap-focus>
        </b-datepicker>
      </b-field>
      <b-button class="is-success" @click="updateReport">Update</b-button>
      <hr>
      <div class="box">
        <table class="table is-striped">
          <thead>
          <th>Sales Tax</th>
          <th>Taxes Charged</th>
          </thead>
          <tbody>
          <tr v-for="(amount, incomeName) in incomeReport" :key="incomeName">
            <td>{{ incomeName }}</td>
            <td>{{ amount }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.heading, .title {
  display: block;
}

.text-muted {
  opacity: 0.4;
}

</style>

<script>

import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'IncomeReport',
  components: { Breadcrumbs },
  computed: {
    incomeReport () {
      return this.$store.getters['reports/incomeReport']
    }
  },
  data () {
    return {
      breadcrumbs: [
        { name: 'Reports', route: 'reports-home' },
        { name: 'Income Report', route: null }
      ],
      reportingPeriodStart: null,
      reportingPeriodEnd: null
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'reports-home' })
    },
    updateReport () {
      this.$store.dispatch('reports/fetchIncomeReport', this.reportingPeriodId)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Report updated.',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            duration: 4000
          })
        })
    }
  },
  mounted () {
  }
}
</script>
