<template>
  <div class="settings-content-wrapper">
    <breadcrumbs :breadcrumbs="breadcrumbs"></breadcrumbs>
    <h1>Sales Taxes Report</h1>
    <hr>
    <b-field label="Start Date">
      <b-datepicker
        v-model="reportingPeriodStart"
        placeholder="Start"
        icon="calendar-today"
        trap-focus>
      </b-datepicker>
    </b-field>
    <b-field label="End Date">
      <b-datepicker
        v-model="reportingPeriodEnd"
        placeholder="End"
        icon="calendar-today"
        trap-focus>
      </b-datepicker>
    </b-field>
    <b-button class="is-success" @click="updateReport">Update</b-button>
    <hr>
    <div class="box" v-if="salesTaxesReport.length">
      <p><i>Total sales taxes charged to customers between {{ reportingPeriodStart | moment('D MMM YYYY') }} and {{ reportingPeriodEnd | moment('D MMM YYYY') }}.</i></p>
      <table class="table is-striped is-fullwidth">
        <thead>
         <th>Sales Tax</th>
         <th class="has-text-right">Taxes Charged</th>
        </thead>
        <tbody>
         <tr v-for="(salesTax, id) in salesTaxesReport" :key="id">
           <td>{{ salesTax.name }}</td>
           <td class="has-text-right">{{ salesTax.total.toFixed(2) }}</td>
         </tr>
        </tbody>
      </table>
    </div>
    <div class="notification is-default" v-else>Choose the report dates to start.</div>
  </div>
</template>

<style  lang="scss" scoped>

  .heading, .title {
    display: block;
  }

  .text-muted {
    opacity: 0.4;
  }

</style>

<script>

import moment from 'moment'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'SalesTaxesReport',
  components: { Breadcrumbs },
  computed: {
    salesTaxesReport () {
      return this.$store.getters['reports/salesTaxReport']
    },
    reportParams () {
      const reportStartAt = moment(this.reportingPeriodStart).format('YYYY-MM-DD')
      const reportEndAt = moment(this.reportingPeriodEnd).format('YYYY-MM-DD')
      return {
        reportStartAt: reportStartAt,
        reportEndAt: reportEndAt
      }
    }
  },
  data () {
    return {
      breadcrumbs: [
        { name: 'Reports', route: 'reports-home' },
        { name: 'Sales Taxes', route: null }
      ],
      reportingPeriodStart: null,
      reportingPeriodEnd: null
    }
  },
  methods: {
    cancel () {
      this.$router.push({ name: 'reports-home' })
    },
    updateReport () {
      this.$store.dispatch('reports/fetchSalesTaxesReport', this.reportParams)
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Report updated.',
            type: 'is-success'
          })
        })
        .catch(err => {
          this.$buefy.toast.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            duration: 4000
          })
        })
    }
  },
  mounted () {}
}
</script>
